import React, {useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Screen from '../screen/screen.view';
import Stack from '@mui/material/Stack';
import {LOADING_STATE, TOverviewScreenViewType} from '@/types/screen.types';
import {useTranslation} from 'react-i18next';
import AutomationCreateButton from '../automations/automation-create-button/automation-create-button.view';
import {
  FIFormattedAutomation,
  FIPlayerWithPerformance,
  FITeam,
  TEventAutomationType,
} from '@my-game-plan/types';
import AutomationsDrawer from './automations-drawer/automations-drawer.view';
import {
  IAutomationFilters,
  TFilterOptions,
} from '@/types/event-automations-misc.types';
import Loader from '../common/Loader/Loader.view';
import CustomEmptyState from '../error-states/CustomEmptyState';
import OverviewTableScreen from './table/overview-table-screen.view';
import {useTeams} from '@/context/team.context';
import {getAutomationFilterOptions} from '@/helpers/automation/automation-groups.helper';
import NextOpponent from './next-opponent.view';
import PerformanceCardsOverview from './cards/performance-cards-overview.view';

export interface IOverviewScreenView<
  T = FIPlayerWithPerformance | FIFormattedAutomation,
> {
  loadingState: LOADING_STATE;
  type: TOverviewScreenViewType;
  data: T[];
  error?: string;
  availableFilterOptions: TFilterOptions;
  getData: () => void;
}
interface IOverviewScreenProps {
  title: string;
  automationType: TEventAutomationType;
  views: IOverviewScreenView[];
}

function OverviewScreen(props: IOverviewScreenProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();

  const NAMESPACE =
    props.automationType === 'opponent-automation'
      ? 'opponentAutomations'
      : 'trackers';

  const [_availableFilterOptions, _setAvailableFilterOptions] =
    useState<TFilterOptions>({
      tags: [],
      actions: [],
      players: [],
    });
  const [_selectedFilters, _setSelectedFilters] = useState<
    Record<TOverviewScreenViewType, IAutomationFilters>
  >({
    table: {},
    cards: {},
  });
  const [_currentViewType, _setCurrentViewType] =
    useState<TOverviewScreenViewType>(
      props.automationType === 'opponent-automation' ? 'table' : 'cards',
    );

  const [_currentView, _setCurrentView] = useState<IOverviewScreenView | null>(
    null,
  );
  const [_observingOpponent, _setObservingOpponent] = useState<FITeam | null>(
    null,
  );

  /* Set current view when _currentViewType changed */
  useEffect(() => {
    const _matchingView = props.views.find(
      (view) => view.type === _currentViewType,
    );
    _setCurrentView(_matchingView || null);
  }, [_currentViewType, props.views]);

  /* Get all filter options */
  useEffect(() => {
    if (_teamsContext.ownTeam && _currentView?.data.length) {
      const _allOptions = getAutomationFilterOptions(
        _currentView.type,
        _currentView.data,
        props.automationType,
        _teamsContext.ownPlayers,
        _teamsContext.ownTeam.preferences?.tags || [],
      );

      _setAvailableFilterOptions(_allOptions);
    }

    if (props.automationType === 'opponent-automation') {
      /* Get next opponent */
      const _foundNextOpponent = _teamsContext.all.find(
        (team) => team._id === _teamsContext.nextOpponentId,
      );
      if (_foundNextOpponent) {
        _setObservingOpponent(_foundNextOpponent);
      }
    }
  }, [
    _currentView,
    props.automationType,
    _teamsContext.nextOpponentId,
    _teamsContext.ownTeam,
    _teamsContext.ownPlayers,
  ]);

  useEffect(() => {
    if (
      _currentView?.loadingState === LOADING_STATE.INITING &&
      !_currentView.data.length
    ) {
      _currentView.getData();
    }
  }, [_currentView?.data, _currentView?.loadingState]);

  /*
   * Handlers
   */
  function _onFiltersChange(filters: IAutomationFilters) {
    _setSelectedFilters({
      ..._selectedFilters,
      [_currentViewType]: {
        ..._selectedFilters[_currentViewType],
        ...filters,
      },
    });
  }

  /*
   * Render
   */

  let _Content = null;
  if (_currentView?.loadingState === LOADING_STATE.ERROR) {
    _Content = (
      <CustomEmptyState
        imageType="error"
        header={t('error-states.not-found.header')}
        description={t('error-states.not-found.description')}
      />
    );
  } else if (
    _currentView?.loadingState === LOADING_STATE.SUCCESS &&
    !_currentView.data.length
  ) {
    _Content = (
      <CustomEmptyState
        header={t(`eventAutomations.empty.${NAMESPACE}.header`)}
        description={t(`eventAutomations.empty.${NAMESPACE}.description`)}
      />
    );
  } else if (
    _currentView?.loadingState === LOADING_STATE.SUCCESS &&
    _currentView.type === 'table'
  ) {
    _Content = (
      <OverviewTableScreen
        title=""
        data={_currentView.data as FIFormattedAutomation[]}
        automationType={props.automationType}
        // keyExtractor={_keyExtractor}
        isLoading={false}
        isInitialised={true}
        error={false}
        availableFilterOptions={_availableFilterOptions}
        selectedFilters={_selectedFilters.table}
      />
    );
  } else if (
    _currentView?.loadingState === LOADING_STATE.SUCCESS &&
    _currentView.type === 'cards'
  ) {
    _Content = (
      <PerformanceCardsOverview
        availableFilterOptions={_availableFilterOptions}
        selectedFilters={_selectedFilters.cards}
        data={_currentView.data as FIPlayerWithPerformance[]}
        onUpdateFilters={_onFiltersChange}
      />
    );
  }

  // Render main content based on current view state
  // Create button
  const _createButtonTitle = t(
    `eventAutomations.${
      props.automationType === 'opponent-automation'
        ? 'automations'
        : 'trackers'
    }New`,
  );

  let _HeaderBottomContent = null;
  if (props.automationType === 'opponent-automation' && _observingOpponent) {
    _HeaderBottomContent = <NextOpponent nextOpponent={_observingOpponent} />;
  }
  return (
    <Screen
      loadingState={LOADING_STATE.SUCCESS}
      title={props.title}
      // displayHeaderBorder
      coloredHeader
      HeaderRightContent={
        <AutomationsDrawer
          automationType={props.automationType}
          automations={[]}
          onFiltersChange={_onFiltersChange}
          filters={_selectedFilters[_currentViewType]}
          availableFilterOptions={_availableFilterOptions}
          activeViewType={_currentViewType}
          views={props.views.map((view) => view.type)}
          onViewChange={_setCurrentViewType}
        />
      }
      HeaderBottomContent={_HeaderBottomContent}>
      <Stack>
        {_Content}
        <AutomationCreateButton
          type={props.automationType}
          Button={
            <Fab
              color="primary"
              aria-label="add"
              sx={{position: 'fixed', bottom: '1rem', right: '1rem'}}
              title={_createButtonTitle}>
              <AddIcon />
            </Fab>
          }
        />
      </Stack>
      <Loader
        isVisible={
          _currentView?.loadingState === LOADING_STATE.LOADING ||
          _currentView?.loadingState === LOADING_STATE.INITING
        }
      />
    </Screen>
  );
}

export default OverviewScreen;
