import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Button, {ButtonProps} from '@mui/material/Button';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useVideo} from '@/context/video/video.context';
import {
  FIEventAutomationPostData,
  FIMatch,
  FIMatchEvent,
} from '@my-game-plan/types';

interface VideoPlayerButtonProps extends ButtonProps {
  events: FIMatchEvent[] | null;
  displayTotalEvents?: boolean;
  actionInfo?: Partial<FIEventAutomationPostData>;
  matches?: FIMatch[];
}

function VideoPlayerButton({
  events,
  matches,
  actionInfo,
  displayTotalEvents = true,
  ...buttonProps
}: VideoPlayerButtonProps) {
  const _video = useVideo();
  const {t} = useTranslation();
  const [_hasVideo, _setHasVideo] = useState<boolean>(false);

  useEffect(() => {
    if (!events?.length) {
      _setHasVideo(false);
      return;
    }

    const _matchIdsInEvents = events?.map((event) => event.match._id);
    const _filteredMatches = matches?.filter((match) =>
      _matchIdsInEvents?.includes(match._id),
    );

    const _calculatedHasVideo = _filteredMatches?.some((match) => match.video);
    _setHasVideo(_calculatedHasVideo || false);
  }, [events, matches]);

  if (!events) {
    return (
      <Button startIcon={<PlayArrowIcon />} disabled>
        {t('video-player.button.play')}
      </Button>
    );
  }

  const handleClick = () => {
    _video.openVideoPlayer(events, matches || [], undefined, true, actionInfo);
  };

  return (
    // <Button
    //   iconBefore={<VidPlayIcon label="Play icon" />}
    //   isDisabled={events.length <= 0}
    //   onClick={handleClick}
    //   appearance="primary">
    //   {t('video-player.button.play')} ({events.length})
    // </Button>
    <Button
      startIcon={<PlayArrowIcon />}
      disabled={!_hasVideo}
      onClick={handleClick}
      color={buttonProps.color || 'primary'}
      variant={buttonProps.variant || 'contained'}
      size={buttonProps.size || 'small'}>
      {`${t('video-player.button.play')}${
        displayTotalEvents ? ` (${events.length})` : ''
      }`}
    </Button>
  );
}

export default VideoPlayerButton;
