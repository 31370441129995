import React, {useEffect, useState} from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import {
  FIEventAutomationPostData,
  FIFormattedAutomationPlayerScores,
  FIMatch,
  FIMatchEvent,
} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';
import VideoPlayerButton from '@/components/buttons/VideoPlayerButton';

interface IAutomationPerformanceProps {
  scores: FIFormattedAutomationPlayerScores | null;
  isTeamTracker?: boolean;
  events: FIMatchEvent[];
  actionInfo: Partial<FIEventAutomationPostData>;
  matches: FIMatch[];
}

function AutomationPerformance(
  props: IAutomationPerformanceProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const [_fieldsToShow, _setFieldsToShow] = useState<
    (keyof FIFormattedAutomationPlayerScores)[]
  >([]);
  const {t} = useTranslation();

  useEffect(() => {
    const _newFieldsToShow: (keyof FIFormattedAutomationPlayerScores)[] = [
      'benchmark_average',
      'average',
      'last',
      props.isTeamTracker ? 'matches_played' : 'minutes_played',
      'total_events',
    ];

    if (props.actionInfo.metric) {
      _newFieldsToShow.push('total_score_sum');
      _newFieldsToShow.push('average_score_per_event');
    }
    _setFieldsToShow(_newFieldsToShow);
  }, [props.scores, props.isTeamTracker]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  if (!props.scores) {
    return null;
  }

  return (
    <Stack
      direction="row"
      flex={1}
      justifyContent="space-between"
      alignItems="center">
      {_fieldsToShow.map((field) => {
        const _matchingScore = props.scores?.[field];
        if (!_matchingScore) {
          return (
            <Typography
              key={field}>{`TODO - EMPTY SCORE - ${field}`}</Typography>
          );
        }

        return (
          <Stack key={field}>
            {/* Head */}
            <Stack mb={0.5}>
              <Typography variant="caption">{_matchingScore.label}</Typography>
            </Stack>

            {/* Score + suffix */}
            <Stack
              direction="row"
              alignItems="flex-end"
              gap={0.5}
              sx={{opacity: field === 'benchmark_average' ? 0.5 : 1}}>
              {/* Main value */}
              {typeof _matchingScore.value !== 'undefined' ? (
                <Typography variant="h5" lineHeight={1} fontWeight={700}>
                  {_matchingScore.formattedValue}
                </Typography>
              ) : (
                <Typography
                  variant="h5"
                  lineHeight={1}
                  fontWeight={700}
                  color="secondary.main">
                  {t('eventAutomations.values.notAvailable')}
                </Typography>
              )}
              {/* Suffix, eg. "X below average" */}
              {_matchingScore.valueSuffix &&
                typeof _matchingScore.value !== 'undefined' && (
                  <Tooltip title={_matchingScore.diffTooltip}>
                    <Typography
                      // variant="body2"
                      lineHeight={1.3}
                      fontWeight={700}
                      color={_matchingScore.diffColor}>
                      {_matchingScore.valueSuffix}
                    </Typography>
                  </Tooltip>
                )}
            </Stack>
          </Stack>
        );
      })}
      {/* <Button color="primary" variant="contained" startIcon={<PlayArrowIcon />}>
        {t('video-player.button.watch')}
      </Button> */}
      <VideoPlayerButton
        size="medium"
        events={props.events}
        actionInfo={props.actionInfo}
        displayTotalEvents={false}
        matches={props.matches}
      />
    </Stack>
  );
}

export default AutomationPerformance;
