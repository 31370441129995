import React, {useState} from 'react';
import Menu from '@mui/material/Menu';

import ListItem from '@mui/material/ListItem';

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import SettingsIcon from '@mui/icons-material/Settings';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import {useTranslation} from 'react-i18next';
import {lighten, useTheme} from '@mui/material';
import {useVideo} from '@/context/video/video.context';

import styles from './VideoControlBar.module.scss';
import {TVideoSourceType} from '@my-game-plan/types';

function VideoControlSettings() {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const _videoContext = useVideo();
  const {t} = useTranslation();

  const [_anchorEl, _setAnchorEl] = useState<null | HTMLElement>(null);

  /*
   * Side Effects
   */

  /*
   * Handlers
   */
  function _onMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    _setAnchorEl(event.currentTarget);
  }
  function _onMenuClose() {
    _setAnchorEl(null);
  }

  function _handleVolumeChange(newValue: number) {
    if (_videoContext.muted) _videoContext.setMuted(false);
    _videoContext.setVolume(newValue);
  }

  function _onSourceChange(event: SelectChangeEvent<TVideoSourceType>) {
    _videoContext.changeVideoSource(event.target.value as TVideoSourceType);
  }

  /*
   * Render
   */

  const _isMenuOpened = Boolean(_anchorEl);
  const PAPER_BG = lighten(_theme.palette.background.default, 0.15);

  const _VolumeIcon =
    _videoContext.muted || _videoContext.volume == 0 ? (
      <VolumeOffIcon className={styles.control_bar_icon} />
    ) : (
      <VolumeUpIcon className={styles.control_bar_icon} />
    );

  let _SourceSelector = null;
  if (
    _videoContext.videoSource &&
    _videoContext.videoSourceConfig &&
    Object.keys(_videoContext.videoSourceConfig).length > 1
  ) {
    _SourceSelector = (
      <ListItem>
        <ListItemText
          primaryTypographyProps={{
            variant: 'body2',
            color: 'text.secondary',
          }}>
          {t('video-player.tooltip.source')}
        </ListItemText>

        <FormControl variant="outlined" size="small">
          <Select
            value={_videoContext.videoSource}
            sx={{fontSize: 14}}
            onChange={_onSourceChange}>
            {Object.keys(_videoContext.videoSourceConfig).map((source) => {
              return (
                <MenuItem key={source} value={source}>
                  {t(`video-player.source.${source}`)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ListItem>
    );
  }
  return (
    <>
      <Tooltip placement="top" title={t('video-player.tooltip.settings')}>
        <IconButton
          color="primary"
          className={styles.IconButton}
          onClick={_onMenuOpen}>
          <SettingsIcon className={styles.control_bar_icon} />
        </IconButton>
      </Tooltip>
      <Menu
        open={_isMenuOpened}
        onClose={_onMenuClose}
        anchorEl={_anchorEl}
        transformOrigin={{vertical: 'bottom', horizontal: 'right'}}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        slotProps={{
          paper: {
            sx: {
              bgcolor: PAPER_BG,
              minWidth: 240,
            },
          },
        }}>
        {/* VOLUME */}
        <ListItem divider>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body2',
              color: 'text.secondary',
            }}>
            {t('video-player.tooltip.volume')}
          </ListItemText>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Tooltip
              placement="top"
              title={
                _videoContext.muted
                  ? t('video-player.tooltip.unmute')
                  : t('video-player.tooltip.mute')
              }>
              <IconButton
                size="small"
                onClick={() => _videoContext.setMuted(!_videoContext.muted)}>
                {_VolumeIcon}
              </IconButton>
            </Tooltip>
            <Slider
              orientation="horizontal"
              min={0}
              max={1}
              step={0.1}
              aria-label="Volume"
              value={_videoContext.muted ? 0 : _videoContext.volume}
              onChange={(e, newVal) => _handleVolumeChange(newVal as number)}
              color="secondary"
              sx={{
                color: 'text.primary',
                width: 80,
                '& .MuiSlider-thumb': {
                  height: 12,
                  width: 12,
                },
              }}
            />
          </Stack>
        </ListItem>

        {/* AUTOPLAY */}
        <ListItem divider>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body2',
              color: 'text.secondary',
            }}>
            {t('video-player.tooltip.autoplay')}
          </ListItemText>
          <Switch
            edge="end"
            checked={_videoContext.autoplay}
            onChange={_videoContext.toggleAutoPlay}
            disabled={_videoContext.playlist.length === 1}
          />
        </ListItem>

        {/* SOURCE */}
        {_SourceSelector}
      </Menu>
    </>
  );
}

export default VideoControlSettings;
