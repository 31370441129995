import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {
  FIMatchEvent,
  FIEventFilters,
  FIFormattedAutomation,
} from '@my-game-plan/types';
import {PropsWithChildren, useState} from 'react';
import {useAuth} from '../auth.context';
import {
  fetchEventsForAutomation,
  getAllAutomations,
} from '@/controllers/event-automations.controller';
import {LOADING_STATE} from '@/types/screen.types';
import {useTeams} from '../team.context';

export interface IOpponentAutomationsAPI {
  opponentAutomations: FIFormattedAutomation[];
  getAll: () => void;
  // isLoading: boolean;
  // hasError: boolean;
  loadingState: LOADING_STATE;
  isInitialised: boolean;
  setIsInitialised: (isInitialised: boolean) => void;
  fetchEvents: (id: string, teamID?: string) => Promise<FIMatchEvent[]>;
}

const context = createCustomContext<IOpponentAutomationsAPI>();
export const useOpponentAutomations = createContextHook(context);

function OpponentAutomationsProvider(
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element {
  /*
   * Init
   */
  const _auth = useAuth();
  const [_opponentAutomations, _setOpponentAutomations] = useState<
    FIFormattedAutomation[]
  >([]);
  const _teamsContext = useTeams();

  const [_loadingState, _setloadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_isInitialised, _setIsInitialised] = useState<boolean>(false);

  /*
   * Handlers
   */
  async function _getAll() {
    if (_auth.user) {
      try {
        _setloadingState(LOADING_STATE.LOADING);
        const _fetchedData = await getAllAutomations(
          'opponent-automation',
          _auth.user.team,
          {
            ['team._id']: [_teamsContext.nextOpponentId],
          },
        );
        _setOpponentAutomations(_fetchedData);
        _setloadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        _setloadingState(LOADING_STATE.ERROR);
      } finally {
        _setIsInitialised(true);
      }
    }
  }

  async function _fetchEvents(id: string, teamID?: string) {
    const _filterParams: FIEventFilters = {};
    if (teamID) {
      _filterParams['team._id'] = [teamID];
    }
    return await fetchEventsForAutomation(
      'opponent-automation',
      id,
      _filterParams,
    );
  }

  return createProvider(context, props, {
    opponentAutomations: _opponentAutomations,
    getAll: _getAll,
    loadingState: _loadingState,
    isInitialised: _isInitialised,
    setIsInitialised: _setIsInitialised,

    fetchEvents: _fetchEvents,
  });
}

export default OpponentAutomationsProvider;
