import React, {useRef} from 'react';
import ReactPlayer from 'react-player';
import cn from 'classnames';

import {useTranslation} from 'react-i18next';

import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

import CustomEmptyState from '@/components/error-states/CustomEmptyState';
import {EmailButton} from '@/components/buttons/EmailButton';
import {useVideoPlaylist} from '@/context/video/video-playlist.context';
import {useVideo} from '@/context/video/video.context';

import styles from './video.module.scss';
import Controls from '../controls/VideoControlBar';
import Edit from '../edit/Edit';
import PlaylistBar from '../playlist/playlistBar/PlaylistBar';
import {HLS_ERROR} from '@/config/errors.config';
import {Typography} from '@mui/material';

const VIDEO_WIDTH = '100%';
const VIDEO_HEIGHT = '100%';
const VIDEO_STRETCH = {width: '100%', height: '100%', objectFit: 'fill'};

function VideoPlayer() {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const focusRef = useRef<HTMLDivElement>(null);

  const {
    currentClip,
    nextClip,
    playClip,
    autoplay,
    playing,
    flipVideoState,
    setVideoInstance,
    fullscreen,
    volume,
    muted,
    setPlayed,
    setVideoElement,
    videoBufferingStatus,
    played,
    error,
    setError,
    isEditMenuOpen,
    isVideoReady,
    setIsVideoReady,
    playlist,
    openPlaylistBar,
    setOpenPlaylistBar,
    relative,
    actionInfo,
    matches,
    clipConfig,
  } = useVideo();

  const _playlist = useVideoPlaylist();

  const VIDEO_CONFIG = {
    file: {
      attributes: {
        style: VIDEO_STRETCH,
      },
      forceHLS: clipConfig.isHLSEnabled,
      hlsVersion: '1.4.12',
    },
  };

  const _vidInstance = (p: ReactPlayer) => {
    setVideoInstance(p);
  };

  const _videoElement = (p: HTMLDivElement) => {
    setVideoElement(p);
  };

  /*
   * Handlers
   */
  function _onVideoEnded() {
    if (autoplay) {
      nextClip();
    }
  }

  /*
   * Render
   */
  const _canPlayVideo = isVideoReady;

  function _renderAdditionalVideoComponents() {
    return (
      <>
        {isEditMenuOpen ? (
          <Edit />
        ) : (
          <PlaylistBar
            focusRef={focusRef}
            events={_playlist.matchesWithEvents}
            playlistLength={playlist.length}
            isPlaylistBarOpen={openPlaylistBar}
            togglePlaylistBar={setOpenPlaylistBar}
            checkedList={_playlist.checkedList}
            toggleSelectAll={_playlist.toggleSelectAll}
            toggleSelectItem={_playlist.toggleSelect}
            currentClip={currentClip}
            playClip={playClip}
            isRelative={relative}
            metric={actionInfo?.metric}
            matches={matches}
          />
        )}
        {_canPlayVideo && <Controls />}
      </>
    );
  }

  let _Content = null;

  const _hasError = Boolean(error);

  if (error) {
    _Content = (
      <Box className={styles.content}>
        <CustomEmptyState
          header={t([
            `error-states.${error}.header`,
            'error-states.video.header',
          ])}
          description={t([
            `error-states.${error}.description`,
            'error-states.video.description',
          ])}
          primaryAction={error ? undefined : <EmailButton />}
        />
      </Box>
    );
  } else {
    _Content = (
      <div className={styles.videoClickWrapper} onClick={flipVideoState}>
        <ReactPlayer
          url={clipConfig.clipUrl}
          width={VIDEO_WIDTH}
          height={VIDEO_HEIGHT}
          config={VIDEO_CONFIG}
          onReady={() => {
            setIsVideoReady(true);
          }}
          onEnded={_onVideoEnded}
          onProgress={(res) => {
            videoBufferingStatus != 'complete'
              ? setPlayed({...played, loaded: res.loaded})
              : setPlayed(res);
          }}
          onError={(error) => {
            if (error !== HLS_ERROR) setError(error);
          }}
          loop={!autoplay || playlist.length < 2}
          volume={volume}
          muted={muted}
          playing={playing && _canPlayVideo}
          id={'video'}
          progressInterval={100}
          ref={_vidInstance}
        />
      </div>
    );
  }

  return (
    <div
      onDoubleClick={fullscreen}
      ref={_videoElement}
      className={styles.container}>
      {_Content}
      {_renderAdditionalVideoComponents()}
      <Fade
        in={(!_hasError && !isVideoReady) || videoBufferingStatus != 'complete'}
        unmountOnExit>
        <Box className={cn(styles.content, styles.content_loading)}>
          <CircularProgress />
          {videoBufferingStatus != 'complete' && (
            <Typography align={'center'} pt={2}>
              {videoBufferingStatus == 'settingTime'
                ? '0%'
                : `${(played.loaded * 100).toFixed(0)}%`}
            </Typography>
          )}
        </Box>
      </Fade>
    </div>
  );
}

export default VideoPlayer;
