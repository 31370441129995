import {
  getShotAnalysis,
  getShotAnalysisEvents,
  getShotAnalysisInsights,
} from '@/controllers/shot-analysis.controller';
import {
  FIMatch,
  FIMatchEvent,
  FIShotAnalysis,
  FIShotAnalysisInsight,
  SHOT_CATEGORIES,
  ShotCategory,
  TShotAnalysisTeam,
  eventsArrayToPitchVisualGroups,
  getPitchVisualForAction,
} from '@my-game-plan/types';
import {PropsWithChildren, useEffect, useState} from 'react';
import {useAuth} from './auth.context';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {useTranslation} from 'react-i18next';
import {IPitchVisual} from '@/types/dashboard/dashboard-pitch-visual.types';
import {useTeams} from './team.context';
import {formatShotAnalysisData} from '@/helpers/shot-analysis.helper';
import {useAnalytics} from './analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';
import {useMatches} from './matches.context';

export interface ShotAnalysisAPI {
  clear: () => void;

  // Analysis
  getShotAnalysis: (type: TShotAnalysisTeam) => void;
  analysis: FIShotAnalysis | null;
  error: string | null;
  isLoadingAnalysis: boolean;

  // Benchmark
  benchmarkLabel: string;

  // Events / Pitch Visual
  pitchVisualData: IPitchVisual | undefined;
  isLoadingEvents: boolean;
  eventsError: string | null;
  events: FIMatchEvent[];
  matches: FIMatch[];

  pitchVisualShotTypes: ShotCategory[];
  onPitchVisualShotTypeSelect: (
    shotType: ShotCategory,
    type?: TShotAnalysisTeam,
  ) => void;

  // Insights
  insights: FIShotAnalysisInsight[];
  insightsError: string | null;
  isLoadingInsights: boolean;
}

const context = createCustomContext<ShotAnalysisAPI>();
export const useShotAnalysis = createContextHook(context);

export function ShotAnalysisProvider(
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element {
  /*
   * Hooks n State
   */
  /* Analysis */
  const [_analysis, _setAnalysis] = useState<FIShotAnalysis | null>(null);

  const [_error, _setError] = useState<string | null>(null);
  const [_isLoadingAnalysis, _setIsLoadingAnalysis] = useState<boolean>(true);

  /* Events */
  const [_events, _setEvents] = useState<FIMatchEvent[]>([]);
  const [_matches, _setMatches] = useState<FIMatch[]>([]);
  const [_isLoadingEvents, _setIsLoadingEvents] = useState<boolean>(true);
  const [_eventsError, _setEventsError] = useState<string | null>(null);
  const [_pitchVisualShotTypes, _setPitchVisualShotTypes] =
    useState<ShotCategory[]>(SHOT_CATEGORIES);
  const [_pitchVisualData, _setPitchVisualData] = useState<
    IPitchVisual | undefined
  >(undefined);

  /* Insights */
  const [_insightsError, _setInsightsError] = useState<string | null>(null);
  const [_insights, _setInsights] = useState<FIShotAnalysisInsight[]>([]);
  const [_isLoadingInsights, _setIsLoadingInsights] = useState<boolean>(true);

  /* Benchmark */
  const [_benchmarkLabel, _setBenchmarkLabel] = useState<string>('');

  const _authContext = useAuth();
  const _teamsContext = useTeams();
  const _matchesContext = useMatches();
  const _analyticsContext = useAnalytics();
  const {t} = useTranslation();

  /* Set benchmark label */
  useEffect(() => {
    _setBenchmarkLabel(t('shotAnalysis.benchmark.average.league'));
  }, []);

  /* Group (and filter) events after fetching */
  useEffect(() => {
    const _filteredEvents = _events.filter((event) => {
      return (
        event.shot?.shot_category &&
        _pitchVisualShotTypes.includes(event.shot?.shot_category)
      );
    });
    const _groups = eventsArrayToPitchVisualGroups('shot', _filteredEvents);
    const _pitchVisualConfig = getPitchVisualForAction('shot');

    _setPitchVisualData({
      ..._pitchVisualConfig,
      linkedBlock: 'events',
      tabs: [
        {
          linkedTable: 'events',
          groups: _groups,
        },
      ],
    });
  }, [_events, _pitchVisualShotTypes]);

  /* Define matches occuring in events */
  useEffect(() => {
    const _matchIdsInEvents = _events.map((event) => event.match._id);
    const _filteredMatches = _matchesContext.ownMatches.filter((match) =>
      _matchIdsInEvents.includes(match._id),
    );

    _setMatches(_filteredMatches);
  }, [_events, _matchesContext.ownMatches]);

  /*
   * Handlers
   */
  async function _fetchEvents(teamId: string, type: TShotAnalysisTeam) {
    try {
      _setIsLoadingEvents(true);
      const _fetchedEvents = await getShotAnalysisEvents(teamId, type);
      _setEvents(_fetchedEvents);
    } catch (error) {
      _setError((error as Error).message);
    } finally {
      _setIsLoadingEvents(false);
    }
  }

  async function _fetchInsights(teamId: string, type: TShotAnalysisTeam) {
    try {
      _setIsLoadingInsights(true);
      const _fetchedInsights = await getShotAnalysisInsights(teamId, type);
      _setInsights(_fetchedInsights);
    } catch (error) {
      _setInsightsError((error as Error).message);
    } finally {
      _setIsLoadingInsights(false);
    }
  }
  async function _getShotAnalysis(type: TShotAnalysisTeam) {
    if (_authContext.user) {
      _setIsLoadingAnalysis(true);
      _setAnalysis(null);

      try {
        const _fetchedAnalysis = await getShotAnalysis(
          _authContext.user.team,
          type,
        );

        /* Fetch events */
        _fetchEvents(_authContext.user.team, type);
        _fetchInsights(_authContext.user.team, type);

        _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_SHOT_ANALYSIS, {
          team_id: _authContext.user.team,
          type: type,
        });
        _setAnalysis(
          formatShotAnalysisData(_fetchedAnalysis, _teamsContext.all),
        );
      } catch (error) {
        //
        _setError((error as Error).message);
      } finally {
        _setIsLoadingAnalysis(false);
      }
    }
  }

  function _onPitchVisualShotTypeSelect(
    shotType: ShotCategory,
    type?: TShotAnalysisTeam,
  ) {
    const _shotTypesToSave = [..._pitchVisualShotTypes];
    const _indexOfShotType = _shotTypesToSave.indexOf(shotType);

    if (_indexOfShotType > -1) {
      _shotTypesToSave.splice(_indexOfShotType, 1);
    } else {
      _shotTypesToSave.push(shotType);
    }

    if (_authContext.user && type) {
      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.FILTERED_SHOT_ANALYSIS_TABLE,
        {
          team_id: _authContext.user.team,
          type: type,
          shot_categories: _shotTypesToSave,
        },
      );
    }

    _setPitchVisualShotTypes(_shotTypesToSave);
  }

  function _clear() {
    _setError(null);
    _setAnalysis(null);
    _setEvents([]);
    _setEventsError(null);
    _setInsights([]);
  }

  /*
   * Render
   */
  return createProvider(context, props, {
    clear: _clear,

    getShotAnalysis: _getShotAnalysis,
    analysis: _analysis,
    error: _error,
    isLoadingAnalysis: _isLoadingAnalysis,

    benchmarkLabel: _benchmarkLabel,

    pitchVisualData: _pitchVisualData,
    pitchVisualShotTypes: _pitchVisualShotTypes,
    isLoadingEvents: _isLoadingEvents,
    eventsError: _eventsError,
    events: _events,
    matches: _matches,
    onPitchVisualShotTypeSelect: _onPitchVisualShotTypeSelect,

    insights: _insights,
    isLoadingInsights: _isLoadingInsights,
    insightsError: _insightsError,
  });
}
