import {
  FIEventAutomationPostData,
  FIFormattedAutomation,
  PlayerPosition,
  TEventAutomationType,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import {useTranslation} from 'react-i18next';
import AutomationsTableRow, {
  IAutomationTableCell,
} from './automations-table-row.view';
import {useTheme} from '@mui/material';
import AutomationCreateButton from '@/components/automations/automation-create-button/automation-create-button.view';

interface IAutomationsTableProps {
  automations: FIFormattedAutomation[];
  automationType: TEventAutomationType;
  benchmarkLabel: string;
  positions?: PlayerPosition[];
  data?: Partial<FIEventAutomationPostData>;
}

function AutomationsTable(props: IAutomationsTableProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const {breakpoints} = useTheme();
  const [_cells, _setCells] = useState<IAutomationTableCell[]>([]);

  useEffect(() => {
    const _typeLabel = t(
      `eventAutomations.${
        props.automationType === 'tracker' ? 'trackers' : 'automations'
      }`,
      {count: 1},
    );

    const _newConfig: IAutomationTableCell[] = [
      // Title cell
      {
        label: _typeLabel,
        key: 'title',
      },
      {
        label: t('eventAutomations.properties.score'),
        key: 'score',
        align: 'left',
      },

      {
        label: t('eventAutomations.benchmark.benchmark'),
        key: 'benchmark_average',
        // align: 'right',
      },
      {
        label: t('eventAutomations.properties.average'),
        key: 'average',
        // align: 'right',
      },
      {
        label: t('eventAutomations.properties.lastMatch'),
        key: 'last_match',
        hideOnSmallScreen: true,
        // align: 'right',
      },
      {
        label: t('eventAutomations.properties.tags'),
        key: 'tags',
        hideOnSmallScreen: true,
      },
      {
        label: '',
        key: 'buttons',
        align: 'right',
      },
    ];

    _setCells(_newConfig);
  }, [props.automations]);

  /*
   * Handlers
   */

  /*
   * Render
   */

  const _createButtonTitle = t(
    `eventAutomations.${
      props.automationType === 'opponent-automation'
        ? 'automations'
        : 'trackers'
    }New`,
  );
  return (
    <Stack sx={{alignItems: 'flex-start'}}>
      <Table
        sx={{
          '& .MuiTableCell-root': {
            '&: first-of-type': {
              pl: 0.5,
            },
            '&: last-of-type': {
              pr: 0.5,
            },
          },
        }}>
        <TableHead>
          <TableRow>
            {_cells.map((cell) => {
              return (
                <TableCell
                  key={cell.key}
                  align={cell.align}
                  sx={{
                    py: 0.5,
                    borderBottom: 'none',
                    [breakpoints.down('md')]: {
                      display: cell.hideOnSmallScreen ? 'none' : undefined,
                    },
                    width: cell.key === 'title' ? '25%' : undefined,
                  }}>
                  {cell.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.automations.map((automation) => {
            return (
              <AutomationsTableRow
                key={automation._id}
                automation={automation}
                automationType={props.automationType}
                cells={_cells}
                benchmarkLabel={props.benchmarkLabel}
              />
            );
          })}
        </TableBody>
      </Table>
      <AutomationCreateButton
        type={props.automationType}
        optionalActionInfo={props.data}
        Button={
          <Button startIcon={<AddIcon />} color="secondary" sx={{mt: 1}}>
            {_createButtonTitle}
          </Button>
        }
      />
    </Stack>
  );
}

export default AutomationsTable;
