import {
  FIMatchEvent,
  FIEventAutomationPostData,
  FIEventAutomationSubject,
  FIEventFilters,
  TEventAutomationType,
  FIBenchmarkData,
  IBenchmarkOptions,
  FIFormattedAutomation,
  IEventAutomationsUpdateAllQueryParams,
  FIPlayerWithPerformance,
} from '@my-game-plan/types';

import {useAPI} from '@/hooks/api.hooks';
import {Resource} from '@/types/api.types';
import {AUTOMATION_API_CONFIG} from '@/config/api.config';
import {REQUEST_ERRORS} from '@/config/errors.config';

const api = useAPI();

function getAutomationTypeEndpoint(type: TEventAutomationType): Resource {
  if (type === 'tracker') {
    return Resource.trackers;
  } else if (type === 'shadow-tracker') {
    return Resource.shadow_trackers;
  }
  return Resource.opponent_automations;
}

export async function getAllAutomations(
  type: TEventAutomationType,
  teamID: string,
  params?: FIEventFilters,
): Promise<FIFormattedAutomation[]> {
  const _resource = getAutomationTypeEndpoint(type);
  try {
    const {data} = await api.get<FIFormattedAutomation[]>({
      resource: _resource,
      url: AUTOMATION_API_CONFIG.getAll(teamID),
      params: params,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    return new Promise<FIFormattedAutomation[]>((res) => res([]));
  }
}

export async function getAllAutomationsGroupedByPlayer(
  type: TEventAutomationType,
  teamID: string,
): Promise<FIPlayerWithPerformance[]> {
  const _resource = getAutomationTypeEndpoint(type);
  try {
    const {data} = await api.get<FIPlayerWithPerformance[]>({
      resource: _resource,
      url: AUTOMATION_API_CONFIG.getAllGroupedPerPlayer(teamID),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    return new Promise<FIPlayerWithPerformance[]>((res) => res([]));
  }
}

export const getSingleAutomation = async (
  type: TEventAutomationType,
  id: string,
  params?: FIEventFilters,
): Promise<FIFormattedAutomation> => {
  const _resource = getAutomationTypeEndpoint(type);
  try {
    const {data} = await api.get<FIFormattedAutomation>({
      resource: _resource,
      url: AUTOMATION_API_CONFIG.getOne(id),
      params: params,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    throw Error;
  }
};

export const fetchEventsForAutomation = async (
  type: TEventAutomationType,
  id: string,
  filters?: FIEventFilters,
): Promise<FIMatchEvent[]> => {
  const _resource = getAutomationTypeEndpoint(type);
  try {
    const {data} = await api.get<FIMatchEvent[]>({
      resource: _resource,
      url: AUTOMATION_API_CONFIG.getEvents(id),
      params: filters,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error('Error fetching OpponentAutomation events');
  }
};

export const addAutomation = async (
  type: TEventAutomationType,
  opponentAutomation: Partial<FIEventAutomationPostData>,
): Promise<FIFormattedAutomation | undefined> => {
  const _resource = getAutomationTypeEndpoint(type);
  try {
    const {data} = await api.post<FIFormattedAutomation>({
      resource: _resource,
      url: AUTOMATION_API_CONFIG.addOne,
      data: opponentAutomation,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error('Error adding opponentAutomation');
  }
};

export const editAutomation = async (
  type: TEventAutomationType,
  id: string,
  opponentAutomation: Partial<FIEventAutomationPostData>,
): Promise<FIFormattedAutomation> => {
  const _resource = getAutomationTypeEndpoint(type);
  try {
    const {data} = await api.put<FIFormattedAutomation>({
      resource: _resource,
      url: AUTOMATION_API_CONFIG.editOne(id),
      data: opponentAutomation,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error('Error adding opponentAutomation');
  }
};

export const shareAutomation = async (
  type: TEventAutomationType,
  id: string,
  data: FIEventAutomationSubject,
): Promise<FIEventAutomationSubject> => {
  const _resource = getAutomationTypeEndpoint(type);
  try {
    const {data: res} = await api.put<FIEventAutomationSubject>({
      resource: _resource,
      url: AUTOMATION_API_CONFIG.shareOne(id),
      data: data,
    });

    if (!res) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return res;
  } catch (error) {
    throw new Error('Error when sharing opponentAutomation');
  }
};

export const deleteAutomation = async (
  type: TEventAutomationType,
  id: string,
): Promise<void> => {
  const _resource = getAutomationTypeEndpoint(type);
  try {
    await api.del<void>({
      resource: _resource,
      url: AUTOMATION_API_CONFIG.deleteOne(id),
    });

    return;
  } catch (error) {
    throw new Error('Error adding OpponentAutomation');
  }
};

export const benchmarkAutomation = async (
  type: TEventAutomationType,
  id: string,
  params: IBenchmarkOptions,
  teamId?: string,
): Promise<FIBenchmarkData> => {
  const _resource = getAutomationTypeEndpoint(type);

  try {
    const {data} = await api.get<FIBenchmarkData>({
      resource: _resource,
      url: AUTOMATION_API_CONFIG.benchmarkOne(id),
      params: {...params, 'team._id': teamId},
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
};

export const updateDataForAutomations = async (
  type: TEventAutomationType,
  teamId: string,
  params: IEventAutomationsUpdateAllQueryParams,
): Promise<FIFormattedAutomation[]> => {
  try {
    const _resource = getAutomationTypeEndpoint(type);
    const {data} = await api.get<FIFormattedAutomation[]>({
      resource: _resource,
      url: AUTOMATION_API_CONFIG.updateDataForAutomations(teamId),
      params: params,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
};
